.ps {
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
  overflow: hidden !important;
}

.ps__rail-x {
  opacity: 0;
  height: 15px;
  transition: background-color .2s linear, opacity .2s linear;
  display: none;
  position: absolute;
  bottom: 0;
}

.ps__rail-y {
  opacity: 0;
  width: 15px;
  transition: background-color .2s linear, opacity .2s linear;
  display: none;
  position: absolute;
  right: 0;
}

.ps--active-x > .ps__rail-x, .ps--active-y > .ps__rail-y {
  background-color: #0000;
  display: block;
}

.ps:hover > .ps__rail-x, .ps:hover > .ps__rail-y, .ps--focus > .ps__rail-x, .ps--focus > .ps__rail-y, .ps--scrolling-x > .ps__rail-x, .ps--scrolling-y > .ps__rail-y {
  opacity: .6;
}

.ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking {
  opacity: .9;
  background-color: #eee;
}

.ps__thumb-x {
  height: 6px;
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, height .2s ease-in-out;
  position: absolute;
  bottom: 2px;
}

.ps__thumb-y {
  width: 6px;
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, width .2s ease-in-out;
  position: absolute;
  right: 2px;
}

.ps__rail-x:hover > .ps__thumb-x, .ps__rail-x:focus > .ps__thumb-x, .ps__rail-x.ps--clicking .ps__thumb-x {
  height: 11px;
  background-color: #999;
}

.ps__rail-y:hover > .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y, .ps__rail-y.ps--clicking .ps__thumb-y {
  width: 11px;
  background-color: #999;
}

@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}

.scrollbar-container {
  height: 100%;
  position: relative;
}

.rdw-option-wrapper {
  min-width: 25px;
  height: 20px;
  cursor: pointer;
  text-transform: capitalize;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  margin: 0 4px;
  padding: 5px;
  display: flex;
}

.rdw-option-wrapper:hover {
  box-shadow: 1px 1px #bfbdbd;
}

.rdw-option-wrapper:active, .rdw-option-active {
  box-shadow: inset 1px 1px #bfbdbd;
}

.rdw-option-disabled {
  opacity: .3;
  cursor: default;
}

.rdw-dropdown-wrapper {
  height: 30px;
  cursor: pointer;
  text-transform: capitalize;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin: 0 3px;
}

.rdw-dropdown-wrapper:focus {
  outline: none;
}

.rdw-dropdown-wrapper:hover {
  background-color: #fff;
  box-shadow: 1px 1px #bfbdbd;
}

.rdw-dropdown-wrapper:active {
  box-shadow: inset 1px 1px #bfbdbd;
}

.rdw-dropdown-carettoopen {
  height: 0;
  width: 0;
  border-top: 6px solid #000;
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
  position: absolute;
  top: 35%;
  right: 10%;
}

.rdw-dropdown-carettoclose {
  height: 0;
  width: 0;
  border-bottom: 6px solid #000;
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
  position: absolute;
  top: 35%;
  right: 10%;
}

.rdw-dropdown-selectedtext {
  height: 100%;
  align-items: center;
  padding: 0 5px;
  display: flex;
  position: relative;
}

.rdw-dropdown-optionwrapper {
  z-index: 100;
  width: 98%;
  max-height: 250px;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin: 0;
  padding: 0;
  position: relative;
  overflow-y: scroll;
}

.rdw-dropdown-optionwrapper:hover {
  background-color: #fff;
  box-shadow: 1px 1px #bfbdbd;
}

.rdw-dropdownoption-default {
  min-height: 25px;
  align-items: center;
  padding: 0 5px;
  display: flex;
}

.rdw-dropdownoption-highlighted {
  background: #f1f1f1;
}

.rdw-dropdownoption-active {
  background: #f5f5f5;
}

.rdw-dropdownoption-disabled {
  opacity: .3;
  cursor: default;
}

.rdw-inline-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
}

.rdw-inline-dropdown {
  width: 50px;
}

.rdw-inline-dropdownoption {
  height: 40px;
  justify-content: center;
  display: flex;
}

.rdw-block-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
}

.rdw-block-dropdown {
  width: 110px;
}

.rdw-fontsize-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
}

.rdw-fontsize-dropdown {
  min-width: 40px;
}

.rdw-fontsize-option {
  justify-content: center;
  display: flex;
}

.rdw-fontfamily-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
}

.rdw-fontfamily-dropdown {
  width: 115px;
}

.rdw-fontfamily-placeholder {
  white-space: nowrap;
  max-width: 90px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.rdw-fontfamily-optionwrapper {
  width: 140px;
}

.rdw-list-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
}

.rdw-list-dropdown {
  width: 50px;
  z-index: 90;
}

.rdw-list-dropdownOption {
  height: 40px;
  justify-content: center;
  display: flex;
}

.rdw-text-align-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
}

.rdw-text-align-dropdown {
  width: 50px;
  z-index: 90;
}

.rdw-text-align-dropdownOption {
  height: 40px;
  justify-content: center;
  display: flex;
}

.rdw-right-aligned-block {
  text-align: right;
}

.rdw-left-aligned-block {
  text-align: left !important;
}

.rdw-center-aligned-block {
  text-align: center !important;
}

.rdw-justify-aligned-block {
  text-align: justify !important;
}

.rdw-right-aligned-block > div, .rdw-left-aligned-block > div, .rdw-center-aligned-block > div, .rdw-justify-aligned-block > div {
  display: inline-block;
}

.rdw-colorpicker-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
  position: relative;
}

.rdw-colorpicker-modal {
  width: 175px;
  height: 175px;
  z-index: 100;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  flex-direction: column;
  padding: 15px;
  display: flex;
  position: absolute;
  top: 35px;
  left: 5px;
  box-shadow: 3px 3px 5px #bfbdbd;
}

.rdw-colorpicker-modal-header {
  padding-bottom: 5px;
  display: flex;
}

.rdw-colorpicker-modal-style-label {
  width: 50%;
  text-align: center;
  cursor: pointer;
  padding: 0 10px 5px;
  font-size: 15px;
}

.rdw-colorpicker-modal-style-label-active {
  border-bottom: 2px solid #0a66b7;
}

.rdw-colorpicker-modal-options {
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  margin: 5px auto;
  display: flex;
  overflow: scroll;
}

.rdw-colorpicker-cube {
  width: 22px;
  height: 22px;
  border: 1px solid #f1f1f1;
}

.rdw-colorpicker-option {
  min-height: 20px;
  width: 22px;
  height: 22px;
  min-width: 22px;
  border: none;
  margin: 3px;
  padding: 0;
  box-shadow: inset 1px 2px 1px #bfbdbd;
}

.rdw-colorpicker-option:hover {
  box-shadow: 1px 2px 1px #bfbdbd;
}

.rdw-colorpicker-option:active {
  box-shadow: -1px -2px 1px #bfbdbd;
}

.rdw-colorpicker-option-active {
  box-shadow: 0 0 2px 2px #bfbdbd;
}

.rdw-link-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
  position: relative;
}

.rdw-link-dropdown {
  width: 50px;
}

.rdw-link-dropdownOption {
  height: 40px;
  justify-content: center;
  display: flex;
}

.rdw-link-dropdownPlaceholder {
  margin-left: 8px;
}

.rdw-link-modal {
  width: 235px;
  height: 205px;
  z-index: 100;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  flex-direction: column;
  padding: 15px;
  display: flex;
  position: absolute;
  top: 35px;
  left: 5px;
  box-shadow: 3px 3px 5px #bfbdbd;
}

.rdw-link-modal-label {
  font-size: 15px;
}

.rdw-link-modal-input {
  height: 25px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin-top: 5px;
  margin-bottom: 15px;
  padding: 0 5px;
}

.rdw-link-modal-input:focus {
  outline: none;
}

.rdw-link-modal-buttonsection {
  margin: 0 auto;
}

.rdw-link-modal-target-option {
  margin-bottom: 20px;
}

.rdw-link-modal-target-option > span {
  margin-left: 5px;
}

.rdw-link-modal-btn {
  width: 75px;
  height: 30px;
  cursor: pointer;
  text-transform: capitalize;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin-left: 10px;
}

.rdw-link-modal-btn:hover {
  box-shadow: 1px 1px #bfbdbd;
}

.rdw-link-modal-btn:active {
  box-shadow: inset 1px 1px #bfbdbd;
}

.rdw-link-modal-btn:focus {
  outline: none !important;
}

.rdw-link-modal-btn:disabled {
  background: #ece9e9;
}

.rdw-link-dropdownoption {
  height: 40px;
  justify-content: center;
  display: flex;
}

.rdw-history-dropdown {
  width: 50px;
}

.rdw-embedded-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
  position: relative;
}

.rdw-embedded-modal {
  width: 235px;
  height: 180px;
  z-index: 100;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  display: flex;
  position: absolute;
  top: 35px;
  left: 5px;
  box-shadow: 3px 3px 5px #bfbdbd;
}

.rdw-embedded-modal-header {
  font-size: 15px;
  display: flex;
}

.rdw-embedded-modal-header-option {
  width: 50%;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.rdw-embedded-modal-header-label {
  width: 95px;
  background: #6eb8d4;
  border: 1px solid #f1f1f1;
  border-bottom: 2px solid #0a66b7;
  margin-top: 5px;
}

.rdw-embedded-modal-link-section {
  flex-direction: column;
  display: flex;
}

.rdw-embedded-modal-link-input {
  width: 88%;
  height: 35px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin: 10px 0;
  padding: 0 5px;
  font-size: 15px;
}

.rdw-embedded-modal-link-input-wrapper {
  align-items: center;
  display: flex;
}

.rdw-embedded-modal-link-input:focus {
  outline: none;
}

.rdw-embedded-modal-btn-section {
  justify-content: center;
  display: flex;
}

.rdw-embedded-modal-btn {
  width: 75px;
  height: 30px;
  cursor: pointer;
  text-transform: capitalize;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin: 0 3px;
}

.rdw-embedded-modal-btn:hover {
  box-shadow: 1px 1px #bfbdbd;
}

.rdw-embedded-modal-btn:active {
  box-shadow: inset 1px 1px #bfbdbd;
}

.rdw-embedded-modal-btn:focus {
  outline: none !important;
}

.rdw-embedded-modal-btn:disabled {
  background: #ece9e9;
}

.rdw-embedded-modal-size {
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
  display: flex;
}

.rdw-embedded-modal-size-input {
  width: 80%;
  height: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  font-size: 12px;
}

.rdw-embedded-modal-size-input:focus {
  outline: none;
}

.rdw-emoji-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
  position: relative;
}

.rdw-emoji-modal {
  width: 235px;
  height: 180px;
  z-index: 100;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  flex-wrap: wrap;
  padding: 15px;
  display: flex;
  position: absolute;
  top: 35px;
  left: 5px;
  overflow: auto;
  box-shadow: 3px 3px 5px #bfbdbd;
}

.rdw-emoji-icon {
  height: 24px;
  width: 24px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin: 2.5px;
  font-size: 22px;
  display: flex;
}

.rdw-spinner {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.rdw-spinner > div {
  width: 12px;
  height: 12px;
  background-color: #333;
  border-radius: 100%;
  animation: 1.4s ease-in-out infinite both sk-bouncedelay;
  display: inline-block;
}

.rdw-spinner .rdw-bounce1 {
  animation-delay: -.32s;
}

.rdw-spinner .rdw-bounce2 {
  animation-delay: -.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

.rdw-image-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
  position: relative;
}

.rdw-image-modal {
  width: 235px;
  z-index: 100;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  flex-direction: column;
  padding: 15px;
  display: flex;
  position: absolute;
  top: 35px;
  left: 5px;
  box-shadow: 3px 3px 5px #bfbdbd;
}

.rdw-image-modal-header {
  margin: 10px 0;
  font-size: 15px;
  display: flex;
}

.rdw-image-modal-header-option {
  width: 50%;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.rdw-image-modal-header-label {
  width: 80px;
  background: #f1f1f1;
  border: 1px solid #f1f1f1;
  margin-top: 5px;
}

.rdw-image-modal-header-label-highlighted {
  background: #6eb8d4;
  border-bottom: 2px solid #0a66b7;
}

.rdw-image-modal-upload-option {
  width: 100%;
  color: gray;
  cursor: pointer;
  outline-offset: -10px;
  background-color: #f1f1f1;
  border: none;
  outline: 2px dashed gray;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  padding: 9px 0;
  font-size: 15px;
  display: flex;
}

.rdw-image-modal-upload-option-highlighted {
  outline: 2px dashed #0a66b7;
}

.rdw-image-modal-upload-option-label {
  cursor: pointer;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 15px;
  display: flex;
}

.rdw-image-modal-upload-option-label span {
  padding: 0 20px;
}

.rdw-image-modal-upload-option-image-preview {
  max-width: 100%;
  max-height: 200px;
}

.rdw-image-modal-upload-option-input {
  width: .1px;
  height: .1px;
  opacity: 0;
  z-index: -1;
  position: absolute;
  overflow: hidden;
}

.rdw-image-modal-url-section {
  align-items: center;
  display: flex;
}

.rdw-image-modal-url-input {
  width: 90%;
  height: 35px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin: 15px 0 12px;
  padding: 0 5px;
  font-size: 15px;
}

.rdw-image-modal-btn-section {
  margin: 10px auto 0;
}

.rdw-image-modal-url-input:focus {
  outline: none;
}

.rdw-image-modal-btn {
  width: 75px;
  height: 30px;
  cursor: pointer;
  text-transform: capitalize;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin: 0 5px;
}

.rdw-image-modal-btn:hover {
  box-shadow: 1px 1px #bfbdbd;
}

.rdw-image-modal-btn:active {
  box-shadow: inset 1px 1px #bfbdbd;
}

.rdw-image-modal-btn:focus {
  outline: none !important;
}

.rdw-image-modal-btn:disabled {
  background: #ece9e9;
}

.rdw-image-modal-spinner {
  width: 100%;
  height: 100%;
  opacity: .5;
  position: absolute;
  top: -3px;
  left: 0;
}

.rdw-image-modal-alt-input {
  width: 70%;
  height: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin-left: 5px;
  font-size: 12px;
}

.rdw-image-modal-alt-input:focus {
  outline: none;
}

.rdw-image-modal-alt-lbl {
  font-size: 12px;
}

.rdw-image-modal-size {
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
  display: flex;
}

.rdw-image-modal-size-input {
  width: 40%;
  height: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  font-size: 12px;
}

.rdw-image-modal-size-input:focus {
  outline: none;
}

.rdw-image-mandatory-sign {
  color: red;
  margin-left: 3px;
  margin-right: 3px;
}

.rdw-remove-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
  position: relative;
}

.rdw-history-wrapper {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  display: flex;
}

.rdw-history-dropdownoption {
  height: 40px;
  justify-content: center;
  display: flex;
}

.rdw-history-dropdown {
  width: 50px;
}

.rdw-link-decorator-wrapper {
  position: relative;
}

.rdw-link-decorator-icon {
  cursor: pointer;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 40%;
}

.rdw-mention-link {
  color: #1236ff;
  background-color: #f0fbff;
  border-radius: 2px;
  padding: 1px 2px;
  text-decoration: none;
}

.rdw-suggestion-wrapper {
  position: relative;
}

.rdw-suggestion-dropdown {
  min-width: 100px;
  max-height: 150px;
  z-index: 100;
  background: #fff;
  border: 1px solid #f1f1f1;
  flex-direction: column;
  display: flex;
  position: absolute;
  overflow: auto;
}

.rdw-suggestion-option {
  border-bottom: 1px solid #f1f1f1;
  padding: 7px 5px;
}

.rdw-suggestion-option-active {
  background-color: #f1f1f1;
}

.rdw-hashtag-link {
  color: #1236ff;
  background-color: #f0fbff;
  border-radius: 2px;
  padding: 1px 2px;
  text-decoration: none;
}

.rdw-image-alignment-options-popup {
  width: 105px;
  cursor: pointer;
  z-index: 100;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  padding: 5px 2px;
  display: flex;
  position: absolute;
}

.rdw-alignment-option-left {
  justify-content: flex-start;
}

.rdw-image-alignment-option {
  height: 15px;
  width: 15px;
  min-width: 15px;
}

.rdw-image-alignment, .rdw-image-imagewrapper {
  position: relative;
}

.rdw-image-center {
  justify-content: center;
  display: flex;
}

.rdw-image-left {
  display: flex;
}

.rdw-image-right {
  justify-content: flex-end;
  display: flex;
}

.rdw-image-alignment-options-popup-right {
  right: 0;
}

.rdw-editor-main {
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
}

.rdw-editor-toolbar {
  -webkit-user-select: none;
  user-select: none;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 5px;
  padding: 6px 5px 0;
  font-size: 15px;
  display: flex;
}

.public-DraftStyleDefault-block {
  margin: 1em 0;
}

.rdw-editor-wrapper:focus {
  outline: none;
}

.rdw-editor-wrapper {
  box-sizing: content-box;
}

.rdw-editor-main blockquote {
  border-left: 5px solid #f1f1f1;
  padding-left: 5px;
}

.rdw-editor-main pre {
  background: #f1f1f1;
  border-radius: 3px;
  padding: 1px 10px;
}

.DraftEditor-editorContainer, .DraftEditor-root, .public-DraftEditor-content {
  height: inherit;
  text-align: initial;
}

.public-DraftEditor-content[contenteditable="true"] {
  -webkit-user-modify: read-write-plaintext-only;
}

.DraftEditor-root {
  position: relative;
}

.DraftEditor-editorContainer {
  z-index: 1;
  background-color: #fff0;
  border-left: .1px solid #0000;
  position: relative;
}

.public-DraftEditor-block {
  position: relative;
}

.DraftEditor-alignLeft .public-DraftStyleDefault-block {
  text-align: left;
}

.DraftEditor-alignLeft .public-DraftEditorPlaceholder-root {
  text-align: left;
  left: 0;
}

.DraftEditor-alignCenter .public-DraftStyleDefault-block {
  text-align: center;
}

.DraftEditor-alignCenter .public-DraftEditorPlaceholder-root {
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

.DraftEditor-alignRight .public-DraftStyleDefault-block {
  text-align: right;
}

.DraftEditor-alignRight .public-DraftEditorPlaceholder-root {
  text-align: right;
  right: 0;
}

.public-DraftEditorPlaceholder-root {
  color: #9197a3;
  z-index: 0;
  position: absolute;
}

.public-DraftEditorPlaceholder-hasFocus {
  color: #bdc1c9;
}

.DraftEditorPlaceholder-hidden {
  display: none;
}

.public-DraftStyleDefault-block {
  white-space: pre-wrap;
  position: relative;
}

.public-DraftStyleDefault-ltr {
  direction: ltr;
  text-align: left;
}

.public-DraftStyleDefault-rtl {
  direction: rtl;
  text-align: right;
}

.public-DraftStyleDefault-listLTR {
  direction: ltr;
}

.public-DraftStyleDefault-listRTL {
  direction: rtl;
}

.public-DraftStyleDefault-ol, .public-DraftStyleDefault-ul {
  margin: 16px 0;
  padding: 0;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
  margin-left: 1.5em;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listRTL {
  margin-right: 1.5em;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
  margin-left: 3em;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listRTL {
  margin-right: 3em;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listLTR {
  margin-left: 4.5em;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listRTL {
  margin-right: 4.5em;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listLTR {
  margin-left: 6em;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listRTL {
  margin-right: 6em;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listLTR {
  margin-left: 7.5em;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listRTL {
  margin-right: 7.5em;
}

.public-DraftStyleDefault-unorderedListItem {
  list-style-type: square;
  position: relative;
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0 {
  list-style-type: disc;
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1 {
  list-style-type: circle;
}

.public-DraftStyleDefault-orderedListItem {
  list-style-type: none;
  position: relative;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  text-align: right;
  width: 30px;
  position: absolute;
  left: -36px;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listRTL:before {
  text-align: left;
  width: 30px;
  position: absolute;
  right: -36px;
}

.public-DraftStyleDefault-orderedListItem:before {
  content: counter(ol0) ". ";
  counter-increment: ol0;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1:before {
  content: counter(ol1) ". ";
  counter-increment: ol1;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth2:before {
  content: counter(ol2) ". ";
  counter-increment: ol2;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth3:before {
  content: counter(ol3) ". ";
  counter-increment: ol3;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth4:before {
  content: counter(ol4) ". ";
  counter-increment: ol4;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-reset {
  counter-reset: ol0;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-reset {
  counter-reset: ol1;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-reset {
  counter-reset: ol2;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-reset {
  counter-reset: ol3;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-reset {
  counter-reset: ol4;
}

.ql-container {
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  position: relative;
}

.ql-container.ql-disabled .ql-tooltip {
  visibility: hidden;
}

.ql-container.ql-disabled .ql-editor ul[data-checked] > li:before {
  pointer-events: none;
}

.ql-clipboard {
  height: 1px;
  position: absolute;
  top: 50%;
  left: -100000px;
  overflow-y: hidden;
}

.ql-clipboard p {
  margin: 0;
  padding: 0;
}

.ql-editor {
  box-sizing: border-box;
  height: 100%;
  tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
  outline: none;
  padding: 12px 15px;
  line-height: 1.42;
  overflow-y: auto;
}

.ql-editor > * {
  cursor: text;
}

.ql-editor p, .ql-editor ol, .ql-editor ul, .ql-editor pre, .ql-editor blockquote, .ql-editor h1, .ql-editor h2, .ql-editor h3, .ql-editor h4, .ql-editor h5, .ql-editor h6 {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  margin: 0;
  padding: 0;
}

.ql-editor ol, .ql-editor ul {
  padding-left: 1.5em;
}

.ql-editor ol > li, .ql-editor ul > li {
  list-style-type: none;
}

.ql-editor ul > li:before {
  content: "•";
}

.ql-editor ul[data-checked="true"], .ql-editor ul[data-checked="false"] {
  pointer-events: none;
}

.ql-editor ul[data-checked="true"] > li *, .ql-editor ul[data-checked="false"] > li * {
  pointer-events: all;
}

.ql-editor ul[data-checked="true"] > li:before, .ql-editor ul[data-checked="false"] > li:before {
  color: #777;
  cursor: pointer;
  pointer-events: all;
}

.ql-editor ul[data-checked="true"] > li:before {
  content: "☑";
}

.ql-editor ul[data-checked="false"] > li:before {
  content: "☐";
}

.ql-editor li:before {
  white-space: nowrap;
  width: 1.2em;
  display: inline-block;
}

.ql-editor li:not(.ql-direction-rtl):before {
  text-align: right;
  margin-left: -1.5em;
  margin-right: .3em;
}

.ql-editor li.ql-direction-rtl:before {
  margin-left: .3em;
  margin-right: -1.5em;
}

.ql-editor ol li:not(.ql-direction-rtl), .ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 1.5em;
}

.ql-editor ol li.ql-direction-rtl, .ql-editor ul li.ql-direction-rtl {
  padding-right: 1.5em;
}

.ql-editor ol li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-0;
}

.ql-editor ol li:before {
  content: counter(list-0, decimal) ". ";
}

.ql-editor ol li.ql-indent-1 {
  counter-increment: list-1;
}

.ql-editor ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) ". ";
}

.ql-editor ol li.ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-2 {
  counter-increment: list-2;
}

.ql-editor ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) ". ";
}

.ql-editor ol li.ql-indent-2 {
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-3 {
  counter-increment: list-3;
}

.ql-editor ol li.ql-indent-3:before {
  content: counter(list-3, decimal) ". ";
}

.ql-editor ol li.ql-indent-3 {
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-4 {
  counter-increment: list-4;
}

.ql-editor ol li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) ". ";
}

.ql-editor ol li.ql-indent-4 {
  counter-reset: list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-5 {
  counter-increment: list-5;
}

.ql-editor ol li.ql-indent-5:before {
  content: counter(list-5, lower-roman) ". ";
}

.ql-editor ol li.ql-indent-5 {
  counter-reset: list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-6 {
  counter-increment: list-6;
}

.ql-editor ol li.ql-indent-6:before {
  content: counter(list-6, decimal) ". ";
}

.ql-editor ol li.ql-indent-6 {
  counter-reset: list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-7 {
  counter-increment: list-7;
}

.ql-editor ol li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) ". ";
}

.ql-editor ol li.ql-indent-7 {
  counter-reset: list-8 list-9;
}

.ql-editor ol li.ql-indent-8 {
  counter-increment: list-8;
}

.ql-editor ol li.ql-indent-8:before {
  content: counter(list-8, lower-roman) ". ";
}

.ql-editor ol li.ql-indent-8 {
  counter-reset: list-9;
}

.ql-editor ol li.ql-indent-9 {
  counter-increment: list-9;
}

.ql-editor ol li.ql-indent-9:before {
  content: counter(list-9, decimal) ". ";
}

.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}

.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}

.ql-editor .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}

.ql-editor li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}

.ql-editor .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}

.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}

.ql-editor .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}

.ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}

.ql-editor .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}

.ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}

.ql-editor .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}

.ql-editor li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}

.ql-editor .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}

.ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}

.ql-editor .ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}

.ql-editor li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em;
}

.ql-editor .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}

.ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 16.5em;
}

.ql-editor .ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}

.ql-editor li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 16.5em;
}

.ql-editor .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}

.ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 19.5em;
}

.ql-editor .ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}

.ql-editor li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 19.5em;
}

.ql-editor .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}

.ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 22.5em;
}

.ql-editor .ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}

.ql-editor li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 22.5em;
}

.ql-editor .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}

.ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 25.5em;
}

.ql-editor .ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}

.ql-editor li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 25.5em;
}

.ql-editor .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}

.ql-editor li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 28.5em;
}

.ql-editor .ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}

.ql-editor li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 28.5em;
}

.ql-editor .ql-video {
  max-width: 100%;
  display: block;
}

.ql-editor .ql-video.ql-align-center {
  margin: 0 auto;
}

.ql-editor .ql-video.ql-align-right {
  margin: 0 0 0 auto;
}

.ql-editor .ql-bg-black {
  background-color: #000;
}

.ql-editor .ql-bg-red {
  background-color: #e60000;
}

.ql-editor .ql-bg-orange {
  background-color: #f90;
}

.ql-editor .ql-bg-yellow {
  background-color: #ff0;
}

.ql-editor .ql-bg-green {
  background-color: #008a00;
}

.ql-editor .ql-bg-blue {
  background-color: #06c;
}

.ql-editor .ql-bg-purple {
  background-color: #93f;
}

.ql-editor .ql-color-white {
  color: #fff;
}

.ql-editor .ql-color-red {
  color: #e60000;
}

.ql-editor .ql-color-orange {
  color: #f90;
}

.ql-editor .ql-color-yellow {
  color: #ff0;
}

.ql-editor .ql-color-green {
  color: #008a00;
}

.ql-editor .ql-color-blue {
  color: #06c;
}

.ql-editor .ql-color-purple {
  color: #93f;
}

.ql-editor .ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}

.ql-editor .ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}

.ql-editor .ql-size-small {
  font-size: .75em;
}

.ql-editor .ql-size-large {
  font-size: 1.5em;
}

.ql-editor .ql-size-huge {
  font-size: 2.5em;
}

.ql-editor .ql-direction-rtl {
  direction: rtl;
  text-align: inherit;
}

.ql-editor .ql-align-center {
  text-align: center;
}

.ql-editor .ql-align-justify {
  text-align: justify;
}

.ql-editor .ql-align-right {
  text-align: right;
}

.ql-editor.ql-blank:before {
  color: #0009;
  content: attr(data-placeholder);
  pointer-events: none;
  font-style: italic;
  position: absolute;
  left: 15px;
  right: 15px;
}

.ql-snow.ql-toolbar:after, .ql-snow .ql-toolbar:after {
  clear: both;
  content: "";
  display: table;
}

.ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button {
  cursor: pointer;
  float: left;
  height: 24px;
  width: 28px;
  background: none;
  border: none;
  padding: 3px 5px;
  display: inline-block;
}

.ql-snow.ql-toolbar button svg, .ql-snow .ql-toolbar button svg {
  float: left;
  height: 100%;
}

.ql-snow.ql-toolbar button:active:hover, .ql-snow .ql-toolbar button:active:hover {
  outline: none;
}

.ql-snow.ql-toolbar input.ql-image[type="file"], .ql-snow .ql-toolbar input.ql-image[type="file"] {
  display: none;
}

.ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #06c;
}

.ql-snow.ql-toolbar button:hover .ql-fill, .ql-snow .ql-toolbar button:hover .ql-fill, .ql-snow.ql-toolbar button:focus .ql-fill, .ql-snow .ql-toolbar button:focus .ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #06c;
}

.ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #06c;
}

@media (pointer: coarse) {
  .ql-snow.ql-toolbar button:hover:not(.ql-active), .ql-snow .ql-toolbar button:hover:not(.ql-active) {
    color: #444;
  }

  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-fill, .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-fill, .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill {
    fill: #444;
  }

  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke, .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke, .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter, .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter {
    stroke: #444;
  }
}

.ql-snow, .ql-snow * {
  box-sizing: border-box;
}

.ql-snow .ql-hidden {
  display: none;
}

.ql-snow .ql-out-bottom, .ql-snow .ql-out-top {
  visibility: hidden;
}

.ql-snow .ql-tooltip {
  position: absolute;
  transform: translateY(10px);
}

.ql-snow .ql-tooltip a {
  cursor: pointer;
  text-decoration: none;
}

.ql-snow .ql-tooltip.ql-flip {
  transform: translateY(-10px);
}

.ql-snow .ql-formats {
  vertical-align: middle;
  display: inline-block;
}

.ql-snow .ql-formats:after {
  clear: both;
  content: "";
  display: table;
}

.ql-snow .ql-stroke {
  fill: none;
  stroke: #444;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
}

.ql-snow .ql-stroke-miter {
  fill: none;
  stroke: #444;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.ql-snow .ql-fill, .ql-snow .ql-stroke.ql-fill {
  fill: #444;
}

.ql-snow .ql-empty {
  fill: none;
}

.ql-snow .ql-even {
  fill-rule: evenodd;
}

.ql-snow .ql-thin, .ql-snow .ql-stroke.ql-thin {
  stroke-width: 1px;
}

.ql-snow .ql-transparent {
  opacity: .4;
}

.ql-snow .ql-direction svg:last-child {
  display: none;
}

.ql-snow .ql-direction.ql-active svg:last-child {
  display: inline;
}

.ql-snow .ql-direction.ql-active svg:first-child {
  display: none;
}

.ql-snow .ql-editor h1 {
  font-size: 2em;
}

.ql-snow .ql-editor h2 {
  font-size: 1.5em;
}

.ql-snow .ql-editor h3 {
  font-size: 1.17em;
}

.ql-snow .ql-editor h4 {
  font-size: 1em;
}

.ql-snow .ql-editor h5 {
  font-size: .83em;
}

.ql-snow .ql-editor h6 {
  font-size: .67em;
}

.ql-snow .ql-editor a {
  text-decoration: underline;
}

.ql-snow .ql-editor blockquote {
  border-left: 4px solid #ccc;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 16px;
}

.ql-snow .ql-editor code, .ql-snow .ql-editor pre {
  background-color: #f0f0f0;
  border-radius: 3px;
}

.ql-snow .ql-editor pre {
  white-space: pre-wrap;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px 10px;
}

.ql-snow .ql-editor code {
  padding: 2px 4px;
  font-size: 85%;
}

.ql-snow .ql-editor pre.ql-syntax {
  color: #f8f8f2;
  background-color: #23241f;
  overflow: visible;
}

.ql-snow .ql-editor img {
  max-width: 100%;
}

.ql-snow .ql-picker {
  color: #444;
  float: left;
  height: 24px;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  position: relative;
}

.ql-snow .ql-picker-label {
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding-left: 8px;
  padding-right: 2px;
  display: inline-block;
  position: relative;
}

.ql-snow .ql-picker-label:before {
  line-height: 22px;
  display: inline-block;
}

.ql-snow .ql-picker-options {
  min-width: 100%;
  white-space: nowrap;
  background-color: #fff;
  padding: 4px 8px;
  display: none;
  position: absolute;
}

.ql-snow .ql-picker-options .ql-picker-item {
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
  display: block;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #ccc;
  z-index: 2;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #ccc;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #ccc;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  z-index: 1;
  margin-top: -1px;
  display: block;
  top: 100%;
}

.ql-snow .ql-color-picker, .ql-snow .ql-icon-picker {
  width: 28px;
}

.ql-snow .ql-color-picker .ql-picker-label, .ql-snow .ql-icon-picker .ql-picker-label {
  padding: 2px 4px;
}

.ql-snow .ql-color-picker .ql-picker-label svg, .ql-snow .ql-icon-picker .ql-picker-label svg {
  right: 4px;
}

.ql-snow .ql-icon-picker .ql-picker-options {
  padding: 4px 0;
}

.ql-snow .ql-icon-picker .ql-picker-item {
  height: 24px;
  width: 24px;
  padding: 2px 4px;
}

.ql-snow .ql-color-picker .ql-picker-options {
  width: 152px;
  padding: 3px 5px;
}

.ql-snow .ql-color-picker .ql-picker-item {
  float: left;
  height: 16px;
  width: 16px;
  border: 1px solid #0000;
  margin: 2px;
  padding: 0;
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  width: 18px;
  margin-top: -9px;
  position: absolute;
  top: 50%;
  right: 0;
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-font .ql-picker-label[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-size .ql-picker-label[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-label]:not([data-label=""]):before {
  content: attr(data-label);
}

.ql-snow .ql-picker.ql-header {
  width: 98px;
}

.ql-snow .ql-picker.ql-header .ql-picker-label:before, .ql-snow .ql-picker.ql-header .ql-picker-item:before {
  content: "Normal";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]:before {
  content: "Heading 1";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]:before {
  content: "Heading 2";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]:before {
  content: "Heading 3";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]:before {
  content: "Heading 4";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]:before {
  content: "Heading 5";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]:before {
  content: "Heading 6";
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]:before {
  font-size: 2em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]:before {
  font-size: 1.5em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]:before {
  font-size: 1.17em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]:before {
  font-size: 1em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]:before {
  font-size: .83em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]:before {
  font-size: .67em;
}

.ql-snow .ql-picker.ql-font {
  width: 108px;
}

.ql-snow .ql-picker.ql-font .ql-picker-label:before, .ql-snow .ql-picker.ql-font .ql-picker-item:before {
  content: "Sans Serif";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]:before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]:before {
  content: "Serif";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]:before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]:before {
  content: "Monospace";
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]:before {
  font-family: Georgia, Times New Roman, serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]:before {
  font-family: Monaco, Courier New, monospace;
}

.ql-snow .ql-picker.ql-size {
  width: 98px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label:before, .ql-snow .ql-picker.ql-size .ql-picker-item:before {
  content: "Normal";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]:before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]:before {
  content: "Small";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]:before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]:before {
  content: "Large";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]:before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]:before {
  content: "Huge";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]:before {
  font-size: 10px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]:before {
  font-size: 18px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]:before {
  font-size: 32px;
}

.ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background-color: #fff;
}

.ql-snow .ql-color-picker.ql-color .ql-picker-item {
  background-color: #000;
}

.ql-toolbar.ql-snow {
  box-sizing: border-box;
  border: 1px solid #ccc;
  padding: 8px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 15px;
}

.ql-toolbar.ql-snow .ql-picker-label {
  border: 1px solid #0000;
}

.ql-toolbar.ql-snow .ql-picker-options {
  border: 1px solid #0000;
  box-shadow: 0 2px 8px #0003;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label, .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: #ccc;
}

.ql-toolbar.ql-snow .ql-color-picker .ql-picker-item.ql-selected, .ql-toolbar.ql-snow .ql-color-picker .ql-picker-item:hover {
  border-color: #000;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0;
}

.ql-snow .ql-tooltip {
  color: #444;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px 12px;
  box-shadow: 0 0 5px #ddd;
}

.ql-snow .ql-tooltip:before {
  content: "Visit URL:";
  margin-right: 8px;
  line-height: 26px;
}

.ql-snow .ql-tooltip input[type="text"] {
  height: 26px;
  width: 170px;
  border: 1px solid #ccc;
  margin: 0;
  padding: 3px 5px;
  font-size: 13px;
  display: none;
}

.ql-snow .ql-tooltip a.ql-preview {
  max-width: 200px;
  text-overflow: ellipsis;
  vertical-align: top;
  display: inline-block;
  overflow-x: hidden;
}

.ql-snow .ql-tooltip a.ql-action:after {
  content: "Edit";
  border-right: 1px solid #ccc;
  margin-left: 16px;
  padding-right: 8px;
}

.ql-snow .ql-tooltip a.ql-remove:before {
  content: "Remove";
  margin-left: 8px;
}

.ql-snow .ql-tooltip a {
  line-height: 26px;
}

.ql-snow .ql-tooltip.ql-editing a.ql-preview, .ql-snow .ql-tooltip.ql-editing a.ql-remove {
  display: none;
}

.ql-snow .ql-tooltip.ql-editing input[type="text"] {
  display: inline-block;
}

.ql-snow .ql-tooltip.ql-editing a.ql-action:after {
  content: "Save";
  border-right: 0;
  padding-right: 0;
}

.ql-snow .ql-tooltip[data-mode="link"]:before {
  content: "Enter link:";
}

.ql-snow .ql-tooltip[data-mode="formula"]:before {
  content: "Enter formula:";
}

.ql-snow .ql-tooltip[data-mode="video"]:before {
  content: "Enter video:";
}

.ql-snow a {
  color: #06c;
}

.ql-container.ql-snow {
  border: 1px solid #ccc;
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  z-index: 1031;
  width: 100%;
  height: 2px;
  background: #29d;
  position: fixed;
  top: 0;
  left: 0;
}

#nprogress .peg {
  width: 100px;
  height: 100%;
  opacity: 1;
  display: block;
  position: absolute;
  right: 0;
  transform: rotate(3deg)translate(0, -4px);
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
}

#nprogress .spinner {
  z-index: 1031;
  display: block;
  position: fixed;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: 2px solid #0000;
  border-color: #29d #0000 #0000 #29d;
  border-radius: 50%;
  animation: .4s linear infinite nprogress-spinner;
}

.nprogress-custom-parent {
  position: relative;
  overflow: hidden;
}

.nprogress-custom-parent #nprogress .spinner, .nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*# sourceMappingURL=index.8b8efa71.css.map */
